//'use strict';
/*** Global variables and Constant define here ***/
// Constant will be named using all uppercase
var MIN_SW_SUPPORT = 1024,
    MIN_SH_SUPPORT = 768;

// Global Variables will be named using Camel Case, with a prefix 'g' before the variable name
var gScreenWidth = $(window).width(),
    gScreenHeight = $(window).height();

/*** APP ***/
var CLASS = {
  _active: 'js-active',
  _hover: 'js-hover',
  _show: 'js-show',
  _stopScroll: 'js-scroll'
}
// APP will be replaced by the project's name, e.g: NIGHTLIFE, WINN, SGINVITE
var APP = {
	_this: {},
  _btnTop: {},
  _bodyObj: {},
  _footer: {},
  _pDistributor: {},
  _pSupplier: {},

  init: function() {
    this._this = $('.page');
    this._btnTop = this._this.find('.btn-top');
    this._bodyObj = $('body, html');
    this._footer = this._bodyObj.find('footer');
    this._pDistributor = this._bodyObj.find('header nav .menu-distributor');
    this._pSupplier = this._bodyObj.find('header nav .menu-supplier');
    
    $(window).scroll(this.scrollTop);   

    this._btnTop.off('click').on('click', function() {
      APP._bodyObj.animate({scrollTop:0}, 300);
    });   

    $(".dropdown .title").click(function () {
      $(this).parent().toggleClass("closed");
    });

    $(".dropdown li").click(function () {
      $(this).parent().parent().toggleClass("closed").find(".title").text($(this).text());
    });   

    if(this._this.hasClass('pPartner')) {       
      PARTNER.init();
    }

    if(this._this.hasClass('pDistributors')) {      
      DISTRIBUTORS.init();      
    }

    if(this._this.hasClass('pOurPartner')) {     
      OURPARTNER.init();     
    }

    if(this._this.hasClass('pSuppliers')) {     
      SUPPLIERS.init();     
    }

    if (this._this.hasClass('pWhatwedo')) {
      menuLeftWWD.init();
    }

    if (this._this.hasClass('home')) {
      HOME.init();
    } 

    HEADER.init();
    EVENTS.init();  
    PRODUCTS.init();
    NEWS.init();
    TABMENU.init();
    CONTACT.init();
  },

  scrollTop: function() {
    var scroll_top = $(window).scrollTop();

    if(scroll_top > 200) {
      APP._btnTop.fadeIn();
    }
    else {
      APP._btnTop.fadeOut();
    }
  },

  resize: function() {
    //this.modulename.resize();
    HOME.resize();
  }
};

$(document).ready(function() {
  APP.init();
});

$(window).resize(function() {
  APP.resize();
});
