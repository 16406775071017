/*** Popup ***/
POPUP = {

  _links: {},
  _overlay: {},
  _current: {},
  _btnClose: {},
  _btnCancel: {},
  _sltArea: {},

  init: function() {
    this._links = $('.js-show-popup');
    this._overlay = $('#overlay');
    this._current = $('.popup-outer');
    this._btnClose = this._current.find('.btn-close');
    this._btnCancel = this._current.find('.btn-cancel');
    this._sltArea = this._current.find('.frm-area > ul > li > a');

    // Add events listener for all elements
    this._links.off('click').on('click', this.onClickShowPopupHandler);
    this._btnClose.off('click').on('click', this.onClickHidePopupHandler);
    this._btnCancel.off('click').on('click', this.onClickHidePopupHandler);
    this._overlay.off('click').on('click', this.onClickHidePopupHandler);
    this._sltArea.off('click').on('click', this.onClickChooseAreaHandler);
  },

  resize: function() {

  },

  onClickShowPopupHandler: function(e) {
    e.preventDefault();

    var self = POPUP,
        item = $(this);
  
    self._overlay.fadeIn('slow');
    self._current.fadeIn('slow');
    $('html,body').addClass(CLASS._stopScroll);
  },

  onClickHidePopupHandler: function(e) {
    e.preventDefault();

    var self = POPUP,
        iframe = {};
    
    self._overlay.fadeOut('slow');
    self._current.fadeOut('slow');
    $('html,body').removeClass(CLASS._stopScroll);
  },

  onClickChooseAreaHandler: function(e) {
    e.preventDefault();

    var self = POPUP,
        links = $(this),
        item = links.parent();

    item.hasClass(CLASS._active) ? item.removeClass(CLASS._active) : (self._sltArea.parent().removeClass(CLASS._active), item.addClass(CLASS._active));
  }
}
