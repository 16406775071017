var MENU_LEFT = {
	_this: {},
	_btnNav: {},
	_navleft: {},
	_openSubs: {},	

	init: function() {
		this._this = $('.menu');
		this._navleft = this._this.find('> .nav');
		this._openSubs = this._navleft.find('.have-sub');

		this._openSubs.off('click').on('click', this.toogleSub);
	},

	toogleSub: function(e) {
		e.preventDefault();
		var item = $(this),
			content = item.next('ul');
		
		item.hasClass(CLASS._active) ? (item.removeClass(CLASS._active), content.slideUp()) : (item.addClass(CLASS._active), content.slideDown());
	},

}