/*** !HOME ***/
var HOME = {
	_this: {},
	_blockSlider: {},
	_blockLogo: {},

	init: function() {
		this._this = $('section.home');
		this._blockSlider = this._this.find('.slider');	
		this._blockLogo = this._this.find('.bl-logo-scroll');	
			
		if (gScreenWidth > 769) {
			this._blockSlider.height(gScreenHeight*0.65);

			var value = gScreenHeight - this._blockSlider.height() - 124;
			this._blockLogo.css({
				'height': value,
				'padding-top': value/5
			});
			$('footer').css('position', 'fixed');
		} else {
			this._blockSlider.css('height', 'auto');
		};	
		
	/*	
		if(gScreenHeight < 1000) {
			
		};
*/
		$('.scroll-logo').scrollbox({
		    direction: 'h',
		    distance: 250
		});	

				
	},

	resize: function() {
		var currentH = $(window).height(),
			heightSlider = currentH * 0.65;
		
		if (gScreenWidth > 769) {
			HOME._blockSlider.height(heightSlider);	
			
			var value = currentH - HOME._blockSlider.height() - 124;
			this._blockLogo.css({
				'height': value,
				'padding-top': value/5
			});
		} else {
			this._blockSlider.css('height', 'auto');
		};	
	}
}
/*** !PRODUCTS ***/
var PRODUCTS = {
	_this: {},

	init: function() {
		this._this = $('.pProduct');
		
		TAB.init();

		if ($('.bl-related-products').length === 1) {
			APP.carosel.init('.bl-related-products');
		};

		if ($('.bl-best-sellers').length === 1) {
			APP.carosel.init('.bl-best-sellers');
		};	    

		MENU_LEFT.init();
		$('#slider').cslider({
			autoplay	: true,
			bgincrement	: 450
		});
		
	},

	scrollNav: function() {
		var scroll_top = $(window).scrollTop();

	  	if (scroll_top > 400) {
	    	$('.left-menu').addClass('js-fixed');
	  	}
	  	else {
	    	$('.left-menu').removeClass('js-fixed');
	   	}
	}
}
/*** !TAB MENU ***/
var TABMENU = {
	_this: {},
	_blockTabItem: {},
	_contentTab: {},
	_numTabItem: {},
	_actTab: 0,

	init: function() {
		this._this = $('.page');
		this._blockTabItem = this._this.find('.block-tab > .tab-function > li > a');		
		this._contentTab = this._this.find('.block-tab > .content-tab');		
		this._blockTabItem.off('click').on('click', this.moveTab);		
	},

	moveTab: function(e) {
		e.preventDefault();

		TABMENU._numTabItem = TABMENU._contentTab.length;		
		TABMENU._blockTabItem.removeClass(CLASS._active);
		$(this).addClass(CLASS._active);
		TABMENU._actTab = $(this).parent().prevAll().length;
		TABMENU._contentTab.removeClass(CLASS._active);
		TABMENU._this.find('.block-tab > .content-tab').eq(TABMENU._actTab).addClass(CLASS._active);		
	},
}
/*** !TAB MENU ***/
var TAB = {
	_this: {},
	_blockTabItem: {},
	_contentTab: {},
	_numTabItem: {},
	_actTab: 0,

	init: function() {
		this._this = $('.bl-tab-item');
		this._blockTabItem = this._this.find('> .tab-function > li > a');		
		this._contentTab = this._this.find('> .content-tab-info');		
		this._blockTabItem.off('click').on('click', this.moveTab);		
	},

	moveTab: function(e) {
		e.preventDefault();

		TAB._numTabItem = TAB._contentTab.length;		
		TAB._blockTabItem.removeClass(CLASS._active);
		$(this).addClass(CLASS._active);
		TAB._actTab = $(this).parent().prevAll().length;
		TAB._contentTab.removeClass(CLASS._active);
		TAB._this.find('> .content-tab-info').eq(TAB._actTab).addClass(CLASS._active);		
	},
}
/*** !ABOUT US ***/
var EVENTS = {
	_this: {},
	_blSlide: {},
	_imgSlide: {},

	init: function() {
		this._this = $('.featured-events');
		this._blSlide = this._this.find('.events');
		this._imgSlide = this._blSlide.find('.item-slide > .img-left');

		//var slider = new Slider(this._this);
		if ($('.featured-events').length === 1) {
			APP.carosel.init('.featured-events');
		};
		
		this.resizeSlide();

		if (gScreenWidth > 768) {
			$(window).on('resize', this.resizeSlide);
		} else {
			EVENTS._blSlide.height(610);
		}
		
	},

	resizeSlide: function() {
		var heightSlide = EVENTS._imgSlide.height();

		EVENTS._blSlide.height(heightSlide);
	}
}

/*** !Carousel ***/
APP.carosel = {

    _this: {},
    _blockCarosel: {},
    _caroselItem: {},
    _btnNext: {},
    _btnPrev: {},
    _widthCarosel: 0,
    _numCarosel: 0,
    _isScroll: false,

    init: function(container) {
      this._this = $(container);
      this._blockCarosel = this._this.find('.btn-control > .list-control');
      this._caroselItem = this._blockCarosel.find('> a');
      this._btnPrev = this._this.find('> .btn-arrows > .btn-prev');
      this._btnNext = this._this.find('> .btn-arrows > .btn-next');
      this._widthCarosel = this._caroselItem.width();
      this._numCarosel = this._caroselItem.length;

      this._blInfo = this._this.find('.item-slide');
      this._blInfo.eq(0).show();     

      this._lengthItem = length;   

      this._btnPrev.off('click').on('click', this.prevCarosel);
      this._btnNext.off('click').on('click', this.nextCarosel);
      
      if (container === '.featured-events') {
      	this._caroselItem.off('click').on('click', this.showContent);
      };     
      
    },

    prevCarosel: function(e) {
      e.preventDefault();

      	var item = $(this),
      		blControl = item.parents().eq(1).find('.list-control'),
      		valueL = blControl.css('left').split('px');     

		if (APP.carosel._isScroll || parseInt(valueL) === 0) return;

		APP.carosel._isScroll = true;      

		blControl.animate({
			left: parseInt(valueL) + APP.carosel._widthCarosel + 10
		}, 1000, function() {       
			APP.carosel.slideComplete();
		})
    },

    nextCarosel: function(e) {
      e.preventDefault();

      	var item = $(this),
      		blControl = item.parents().eq(1).find('.list-control'),
      		valueL = blControl.css('left').split('px');      	 

		if (APP.carosel._isScroll || parseInt(valueL) <= (APP.carosel._this.width() - (APP.carosel._widthCarosel*APP.carosel._numCarosel))) return;

		APP.carosel._isScroll = true;  

		blControl.animate({
			left: parseInt(valueL) - APP.carosel._widthCarosel - 10
		}, 1000, function() {      
			APP.carosel.slideComplete();
		})
    },

    slideComplete: function() {
       APP.carosel._isScroll = false;
    },

    showContent: function(e) {
    	e.preventDefault();

    	var self = APP.carosel,
    		item = $(this);	

    	item.hasClass(CLASS._active) ? (item.removeClass(CLASS._active)) : APP.carosel.showItem(item);
    },

    showItem: function(container) {
    	var item = $(container),
    		num = item.prevAll().length;

    	APP.carosel._caroselItem.removeClass(CLASS._active);
    	item.addClass(CLASS._active);
    	APP.carosel._blInfo.fadeOut();
    	item.parents().eq(2).find('> .events > .item-slide').eq(num).fadeIn();
    }
  }
/*** !NEWS ***/
var NEWS = {
	_this: {},
	_pDetail: {},
	_contentDetail: {},

	init: function() {
		this._this = $('body');
		this._pDetail = this._this.find('.pDetail');
		this._contentDetail = this._pDetail.find('.content');

		/*if(gScreenWidth < 769) {
			this._contentDetail.css('width', 90 + '%');
		}
		else {
			this._contentDetail.css('width', ((gScreenWidth-540)/gScreenWidth * 100) + '%');
		}		
		*/

		if(document.getElementById('grid')) {
			new AnimOnScroll( document.getElementById('grid'), {
				minDuration : 0.4,
				maxDuration : 0.7,
				viewportFactor : 0.2
			} );
		}				
	},
}
/*** !COUNT ***/
var options = {
  useEasing : true, 
  useGrouping : true, 
  separator : '', 
  decimal : '.', 
  prefix : '', 
  suffix : '' 
};

/*** !PARTNER ***/
var PARTNER = {
	_this: {},
	_blStore: {},
	_imgCircleK: {},
	_imgFamilyM: {},
	_imgHotel: {},	

	init: function() {
		this._this = $('.pPartner');
		this._blStore = this._this.find('.block-store');
		this._imgCircleK = this._blStore.find('.circle-k');
		this._imgFamilyM = this._blStore.find('.family-mart');
		this._imgHotel = this._blStore.find('.hotel');		

		var count = new CountUp("my-number", 0, 4000, 0, 2.0, options);	
		count.start();

		var waypoint7 = new Waypoint({
		 	element: document.getElementById('bl-stores'),
		  	handler: function() {	 
			  	PARTNER._imgFamilyM.animate({
			  			right: 365
			  		}, 600 ,function(){					  			
			  			PARTNER._imgCircleK.animate({
				  				right: 175
				  			}, 400, function(){
				  				PARTNER._imgHotel.animate({
				  					right: 0
				  				}, 300);
				  			});			  		  			  		
		  		});
		  		this.destroy();
		  	}
		});
	}
};

/*** !OURPARTNER ***/
var OURPARTNER = {
	_this: {},
	_blStore: {},
	_imgCircleK: {},
	_imgFamilyM: {},
	_imgHotel: {},	

	init: function() {
		this._this = $('.pOurPartner');
		this._blStore = this._this.find('.block-store');
		this._imgCircleK = this._blStore.find('.circle-k');
		this._imgFamilyM = this._blStore.find('.family-mart');
		this._imgHotel = this._blStore.find('.hotel');	
		
		var count = new CountUp("my-number", 0, 5000, 0, 2.0, options);	

		var waypoint = new Waypoint({
		 	element: document.getElementById('bl-top'),
		  	handler: function() {
		  		count.start();
		  		OURPARTNER._imgFamilyM.animate({
			  			right: 365
			  		}, 600 ,function(){					  			
			  			OURPARTNER._imgCircleK.animate({
				  				right: 175
				  			}, 400, function(){
				  				OURPARTNER._imgHotel.animate({
				  					right: 0
				  				}, 300);
				  			});			  		  			  		
		  		});
		  		this.destroy(); 		
		  	}
		});		
	}
};

/*** !SUPPLIERS ***/
var SUPPLIERS = {
	_this: {},		
	_numStart: 0,
	_blTop: {},
	_listSupplier: {},
	_blProcess: {},
	_blCity: {},
	_btnNext: {},
	_btnPrev: {},
	_step: 0,	

	init: function() {
		this._this = $('.pSuppliers');
		this._blTop = this._this.find('.block-why-us');		
		this._listSupplier = this._this.find('.list-suppliers-wrap > .list-suppliers');
		this._blProcess = this._this.find('.processing');
		this._blCity = this._this.find('.block-main-cities');
		this._btnNext = this._listSupplier.find('.btn-next');
		this._btnPrev = this._listSupplier.find('.btn-prev');
		

		/*this._btnNext.off('click').on('click', this.nextList);
		this._btnPrev.off('click').on('click', this.prevList);*/

		var numElement = this._blProcess.find('p > span').prevAll().length;		

		var waypoint5 = new Waypoint({
		 	element: document.getElementById('bl-process'),
		  	handler: function(direction) {
		  		for (var i = 0; i <= numElement; i++) {
			  		SUPPLIERS.myAnimate(i);
			  	};
		  		this.destroy();	
		  	}
		});				
	},	

	myAnimate: function(num) {
		SUPPLIERS._blProcess.find('p > .process0'+(num+1)).delay(200*num).animate({
			opacity: 1
		}, 400)
	}/*,

	nextList: function(e) {
		e.preventDefault();	

		var item_next = $(this),
			itemN = item_next.parent(),
			moduleCarosel = itemN.next(),
			numLeft = moduleCarosel.css('left'),
			_offset = moduleCarosel.find('> li').width(),
			_numElement = moduleCarosel.find('> li').length;				
			
			numLeft = numLeft.substring(0, numLeft.indexOf('p'));
			
			SUPPLIERS._step++;
			if (SUPPLIERS._step >= _numElement) {	
				SUPPLIERS._step = _numElement;		
				return SUPPLIERS._step;
			}
			else {				
				moduleCarosel.stop().animate({
					left: numLeft - _offset
				}, 800);				
			}					
	},

	prevList: function(e) {
		e.preventDefault();	

		var item_prev = $(this),
			itemP = item_prev.parent(),
			moduleCarosel = itemP.next(),
			numRight = moduleCarosel.css('left'),
			_offset = moduleCarosel.find('> li').width();				
			
			numRight = numRight.substring(0, numRight.indexOf('p'));			
		
			SUPPLIERS._step--;
			if (SUPPLIERS._step <= 0) {
				SUPPLIERS._step = 0;
				return SUPPLIERS._step;
			}
			else {							
				moduleCarosel.stop().animate({
					left: numRight - (-_offset)
				}, 800);				
			}					
	}*/
};

/*** !CONTACT US ***/
var CONTACT = {
	_this: {},
	_blockContact: {},
	_blockInfo: {},
	_selectPlace: {},

	init: function() {
		this._this = $('section.pContactus');
		this._blockContact = this._this.find('.block-contact');
		this._blockInfo = this._blockContact.find('.block-contact-info');		
		this._selectPlace = this._blockInfo.find('.select-place');

		if(gScreenWidth > 480) {
			this._blockContact.css('height', gScreenHeight);
		}				

		this._blockInfo.css('width', gScreenWidth - 503);

		//this._selectPlace.selectize();

		var sltLocations = new 	SltBox('.js-slt-locations'),
			sltCatelogies = new SltBox('.js-slt-catelogies'),
			sltSort = new 	SltBox('.js-slt-sort');

		POPUP.init();
	},
};

/*** Select box ***/
var SltBox = function(container) {
	
	var container = $(container),
			title = container.find('> a'),
			showItem = title.find('> span'),
			listItem = container.find('ul'),
			sltItem = listItem.find('> li > a');
				
	sltItem.on('click', selectItem);
	title.on('click', toogleBox);
	$('.scrollbar-discover').tinyscrollbar({ thumbSize: 81 });

	function toogleBox(e) {		
		e.preventDefault();
		title.hasClass(CLASS._active) ? (title.removeClass(CLASS._active), listItem.slideUp()) : (title.addClass(CLASS._active), listItem.slideDown());
	}


	function selectItem(e) {
		e.preventDefault();
		var item = $(this);
		showItem.text(item.text());
		listItem.slideUp();
		title.removeClass(CLASS._active);
	}

	this.getSelected = function() {		
		return showItem.text();
	}	
};

/*** Menu Left page what we do***/
var menuLeftWWD = {
	_wScreen: {},
	_hScreen: {},
	_this: {},
	_target: {},
	_posYtarget: {},

	init: function() {
		this._wScreen = $(window).width();
		this._hScreen = $(window).height();
		this._this = $('.bl-content');
		this._target = this._this.find('.bl-menu-left');
		this._posYtarget = this._target.offset().top;
		this._target.css({
			position: 'relative'
		})

		if (gScreenWidth > 480) {
			$(window).on('scroll', this.moveMenuLeft);
		};
		
	},

	moveMenuLeft: function() {
		var y = $(this).scrollTop();
		if (y < menuLeftWWD._posYtarget) {
			menuLeftWWD._target.stop().animate({
				top: 0
			}, 500)
		}	else {
			menuLeftWWD._target.stop().animate({
				top: y - menuLeftWWD._posYtarget + 70
			}, 500)
		}
	},
};
