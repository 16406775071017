/*** !HEADER ***/
var HEADER = {
  _this: {},
  _btnSearch: {},
  _inputSearch: {},
  _navMobile: {},
  _btnNavMobile: {}, 
  _navMenu: {},
  _btnNavMenu: {},
  _subMenu: {},
  _selectLang: {},
  _menuChild: {},

  init: function() {
    this._this = $('header');
    this._btnSearch = this._this.find('.btn-search');
    this._inputSearch = this._btnSearch.next();
    this._navMobile = this._this.find('> .nav-mobile');
    this._btnNavMobile = this._this.find('> .btn-nav-mobile');
    this._navMenu = this._this.find('> nav');
    this._btnNavMenu = this._navMenu.find('.menu-sub');
    this._subMenu = this._navMenu.find('.list-menu-sub');
    this._selectLang = this._this.find('> .lang-group > .select-lang');
    this._menuChild = this._navMobile.find('.menu-sub');

    this._btnSearch.off('click').on('click', this.showSearch);
    $('.page').off('click').on('click', function(){
      HEADER._inputSearch.fadeOut();
      HEADER._btnSearch.removeClass(CLASS._active);
    });
    this._btnNavMobile.on('click', this.toogleNav);
    this._menuChild.on('click', this.toogleNavSub);
    this._btnNavMenu.on('mouseover', this.showMenuSub);
    this._btnNavMenu.on('mouseout', this.hideMenuSub);
    var sltLang = new  SltBox('.js-slt-lang')
  },

  showSearch: function(e) {
    e.preventDefault();
    var item = $(this);

    item.hasClass(CLASS._active) ? (item.removeClass(CLASS._active), HEADER._inputSearch.fadeOut()) : (item.addClass(CLASS._active), HEADER._inputSearch.fadeIn())
  },

  toogleNav: function(e) {
    e.preventDefault();

    var item = $(this);  
    item.hasClass(CLASS._active) ? (
      item.removeClass(CLASS._active),
      HEADER._navMobile.slideUp()     
    ) : (item.addClass(CLASS._active), HEADER._navMobile.slideDown())
  },

  toogleNavSub: function() {

    var item = $(this),
      subMenu = item.find('.list-menu-sub'); 

    item.hasClass(CLASS._show) ? (
      item.removeClass(CLASS._show),
      subMenu.slideUp()     
    ) : (item.addClass(CLASS._show), subMenu.slideDown())
  },

  showMenuSub: function(e) {
    e.preventDefault();

    var item = $(this),
      submenu = item.find('ul');

    HEADER._btnNavMenu.removeClass(CLASS._hover);
    HEADER._subMenu.hide();

    item.hasClass(CLASS._hover) ? (item.removeClass(CLASS._hover), submenu.hide()) : (item.addClass(CLASS._hover), submenu.show());
  },

  hideMenuSub: function() {

    var item = $(this),
      submenu = item.find('ul');
    
    item.removeClass(CLASS._hover);
    submenu.hide();
  }
}